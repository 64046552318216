import moment from 'moment';
export function diffInDays(startDate, endDate) {
    const diffInMs = new Date(endDate) - new Date(startDate)
    return diffInMs / (1000 * 60 * 60 * 24);
}

export function printSingleDocumentUrl(docType = '', docUid = '') {
    window.open(process.env.VUE_APP_PRINT_URL + '?doc_type=' + docType + '&id=' + docUid);
}

export function printRecapDocumentUrl(docType = '', data = {}) {
    window.open(process.env.VUE_APP_PRINT_URL + '?doc_type=' + docType + '&data=' + encodeURIComponent(JSON.stringify(data)));
}

export const emailFormatter = (value) => {
    if (!value) return value;
    let parts = value.split('@');
    if (parts[1] !== undefined) {
        parts[1] = parts[1].toLowerCase();
        return parts.join('@');
    }
    return parts.join();
}

export function formatDate(value, format) {
    if (value) {
        return moment(String(value)).format(format);
    }
}

export function isAdmin(){
    var adminUser = JSON.parse(localStorage.getItem('currentUser'));

    if(adminUser) return adminUser.id === 1;

    return false;
}